import Vue from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
//import 'ant-design-vue/dist/antd.css';
import router from './router'
import cnzzAnalytics from 'vue-cnzz-analytics'

// 启动插件
if (process.env.NODE_ENV == 'production') {
  Vue.use(cnzzAnalytics, {
    router: router,
    siteIdList: [
      1280148479
    ],
    isDebug: false
  });  
} else {
  Vue.use(cnzzAnalytics, {
    router: router,
    siteIdList: [
      1280148485
    ],
    isDebug: true
  });
}
Vue.config.productionTip = false

Vue.use(Antd)
// Vue.use(Button)
// Vue.use(Avatar)
// Vue.use(List)
// Vue.use(Icon)

router.beforeEach((to, from, next) => {
  if (to.meta.title) {//判断是否有标题
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
