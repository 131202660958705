var data = {
    cnName: "黄伟基",
    enName: "Ricky",
    summary: "产品经理",
    phone: "13410998677",
    wechatSrc: "https://pics-1254552770.cos.ap-guangzhou.myqcloud.com/4420dc4c0dcfa2f1fabf0d9c09a9d20f.jpg",
    description: "2年SaaS产品经验，能够独立完成需求调研、需求输出，具备团队和项目管理经验。",
    list: [
        {
            id: 'poster_ppt',
            title: "海报中心分享PPT",
            author: "黄伟基 Ricky",
            icon: "file-ppt",
            updatedAt: "2021-01-02 00:00:00",
            html: "海报模板中心.html",
        },
        {
            id: 'note_project',
            title: "顾问笔记设计方案",
            author: "黄伟基 Ricky",
            icon: "folder",
            updatedAt: "2020-07-01 00:00:00",
            html: "顾问笔记方案.html",
        },
    ],
}
export default data