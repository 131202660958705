import Vue from 'vue'
import VueRouter from 'vue-router'
import data from "@/data.js"

Vue.use(VueRouter)

export default new VueRouter({
    routes: [
      {
        path: '/',
        component: () => import('./views/Home.vue'),
        meta: {
          title: `Hi~这里是${data.cnName} ${data.enName}`
        }
      },
      {
        path: '/document/:id',
        component: () => import('./views/Document.vue'),
        meta: {
          title: `Hi~这里是${data.cnName} ${data.enName}`
        }
      }
    ]
  })